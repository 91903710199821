import React, { Fragment, useEffect, useState } from "react";
import memberService from "../../services/memberService";
import { useParams } from "react-router-dom";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";

const MemberDetail = () => {
  const [selectedMember, setSelectedMember] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await memberService.get(id);
      if (response) {
        setSelectedMember(response);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Fragment>
      <div className="background-traffic-white min-h-full px-0 py-8 md:px-6 lg:px-8 text-dark-blue">
        {selectedMember && (
          <div className="member-detail-container p-4">
            <div className="grid">
              <div className="col-12 lg:col-7">
                <h1 className="member-title text-4xl mt-3 mb-3 text-dark-blue">
                  {selectedMember.name} {selectedMember.surname}
                </h1>

                <Tag
                  className="mr-2 mb-4 surface-200 text-dark-blue"
                  value={selectedMember.role}
                />

                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedMember.description,
                  }}
                  className="line-height-3 mt-0 mb-5 text-dark-blue text-xl"
                ></p>

                <div className="member-social-links text-lg text-dark-blue mb-3">
                  {selectedMember.instagramAddress && (
                    <Button
                      icon="pi pi-instagram"
                      className="p-button-rounded  background-dark-blue text-white mr-2"
                      onClick={() =>
                        window.open(selectedMember.instagramAddress, "_blank")
                      }
                    />
                  )}
                  {selectedMember.twitterAddress && (
                    <Button
                      icon="pi pi-twitter"
                      className="p-button-rounded background-dark-blue text-white mr-2"
                      onClick={() =>
                        window.open(selectedMember.twitterAddress, "_blank")
                      }
                    />
                  )}
                  {selectedMember.facebookAddress && (
                    <Button
                      icon="pi pi-facebook"
                      className="p-button-rounded background-dark-blue text-white mr-2"
                      onClick={() =>
                        window.open(selectedMember.facebookAddress, "_blank")
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-12 lg:col-5">
                <Image
                  src={selectedMember.image}
                  alt={`${selectedMember.name} ${selectedMember.surname}`}
                  imageClassName="border-round-lg w-12 lg:w-full"
                  className="border-round shadow-2"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default MemberDetail;
