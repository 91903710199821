import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="background-dark-blue px-4 py-6 md:px-6 lg:px-8">
        <div className="border-top-1 border-white border-300 pt-5 text-center">
          <img
            src="/images/logo.png"
            alt="Image"
            className="w-9rem mx-auto lg:mx-0"
          />
          <ul className="list-none p-0 mx-0 my-5 flex justify-content-center flex-column align-items-center lg:flex-row">
            <li>
              <div
                onClick={() => navigate("/hakkimizda")}
                className="text-600 cursor-pointer line-height-3 lg:mr-5 "
              >
                Hakkımızda
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/yazilar")}
                className="text-600 cursor-pointer line-height-3 lg:mr-5 "
              >
                Neler Yaptık
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/etkinlikler")}
                className="text-600 cursor-pointer line-height-3 lg:mr-5 "
              >
                Etkinlikler
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/oyunlar")}
                className="text-600 cursor-pointer line-height-3 lg:mr-5 "
              >
                Oyunlar
              </div>
            </li>
            <li>
              <div
                onClick={() => navigate("/menu")}
                className="text-600 cursor-pointer line-height-3 "
              >
                Kafe
              </div>
            </li>
          </ul>
          <div className="flex align-items-center justify-content-center mb-5">
            <a
              href="https://www.facebook.com/dariofoeskisehir/"
              className="no-underline cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/dariofoeskisehir/"
              className="no-underline cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-instagram"></i>
            </a>
          </div>
          <div className="text-center">
            <a className="mr-5 text-sm text-600 cursor-pointer">
              Dario Fo Eskişehir
            </a>
            <a className="text-sm text-600 cursor-pointer">
              Tüm Hakları Saklıdır
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
