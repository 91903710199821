import axios from "axios";
import appConfig from "../config/appConfig";

const constantService = {
  getAboutUsData: async () => {
    try {
      const response = await axios.get(
        `${appConfig.apiBaseUrl}/constant/5AD892B4-D276-4B8F-80BD-90111DFF9EEF`
      );
      return response.data;
    } catch (error) {
      console.error(`Error getting about us data`, error);
    }
  },
  getSceneData: async () => {
    try {
      const response = await axios.get(
        `${appConfig.apiBaseUrl}/constant/A3F0F125-3744-47BD-A072-D55820F32CC4`
      );
      return response.data;
    } catch (error) {
      console.error(`Error getting scene data:`, error);
    }
  },
};

export default constantService;
