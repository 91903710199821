import axios from "axios";
import appConfig from "../config/appConfig";

const tMediaService = {
  get: async (id) => {
    try {
      const response = await axios.get(`${appConfig.apiBaseUrl}/tMedia/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting tMedia with id ${id}:`, error);
    }
  },
};

export default tMediaService;
