import React, { Fragment, useEffect, useState } from "react";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner"; // Import ProgressSpinner
import albumService from "../../services/albumService";
import tMediaService from "../../services/tMediaService";
import { Galleria } from "primereact/galleria";

const Gallery = () => {
  const [albums, setAlbums] = useState(null);
  const [allImages, setAllImages] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for spinner
  const [visibleAlbumDialog, setVisibleAlbumDialog] = useState(false);
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await albumService.getAll();
      if (response) {
        setAlbums(response);
      }
      setLoading(false); // Set loading to false once albums are fetched
    };

    fetchData();
  }, []);

  const fetchMedia = async (albumId) => {
    const response = await tMediaService.get(albumId);
    if (response) {
      setAllImages(response);
    }
  };

  const itemTemplate = (item) => {
    return (
      <Image
        src={item.data}
        imageStyle={{ height: "300" }}
        alt={item.alt}
        preview
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return <img src={item.data} style={{ height: "60" }} alt={item.alt} />;
  };

  return (
    <Fragment>
      <div className="background-traffic-white min-h-full px-4 py-8 md:px-6 lg:px-8">
        <div className="font-bold text-dark-blue uppercase text-5xl mb-3 text-center">
          Galeri
        </div>

        {loading ? ( // Show spinner while loading is true
          <div className="flex justify-content-center align-items-center min-h-full">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="grid">
            {albums &&
              albums.map((album, index) => {
                return album.status === 1 ? (
                  <div
                    key={index}
                    className="card flex justify-content-center m-3"
                  >
                    <Image
                      src={album.image}
                      alt="Image"
                      width="350"
                      imageClassName="border-round-lg cursor-pointer"
                      onClick={() => {
                        fetchMedia(album.id);
                        setVisibleAlbumDialog(true);
                      }}
                    />
                  </div>
                ) : null;
              })}
          </div>
        )}
      </div>
      <Dialog
        visible={visibleAlbumDialog}
        onHide={() => {
          setVisibleAlbumDialog(false);
          setAllImages(null);
        }}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        {allImages && allImages.length > 0 ? (
          <Galleria
            value={allImages}
            responsiveOptions={responsiveOptions}
            numVisible={5}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          />
        ) : null}
      </Dialog>
    </Fragment>
  );
};

export default Gallery;
