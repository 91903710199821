import React, { useState, useEffect } from "react";
import categoryService from "../../services/categoryService";

const Cafe = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      const response = await categoryService.getAll();

      if (response) {
        const sortedCategories = response
          .filter((category) => category.status === 1)
          .sort((a, b) => a.position - b.position);

        sortedCategories.forEach((category) => {
          category.products = category.products
            .filter((product) => product.status === 1)
            .sort((a, b) => a.position - b.position);
        });

        setCategories(sortedCategories);
      }
    };

    fetchCategoryData();
  }, []);

  const halfIndex = Math.ceil(categories.length / 2);

  return (
    <div className="background-menu  min-h-full px-4 py-8 md:px-6 lg:px-8">
      <div className="font-bold text-dark-blue text-7xl mb-3 text-center uppercase">
        Menü
      </div>
      <div className="grid mt-5">
        <div className="col-12 lg:col-5">
          {categories.slice(0, halfIndex).map((category, index) => (
            <div className="mb-4 p-5" key={index}>
              <div className="relative">
                <div className="font-bold pb-2 text-3xl mb-5 text-dark-blue uppercase">
                  {category.name}
                </div>
              </div>
              <ul className="list-none p-0 m-0">
                {category.products &&
                  category.products.map((item, idx) => (
                    <li key={idx} className="flex justify-content-between my-5">
                      <div>
                        <span className="font-semibold text-dark-blue text-lg">
                          {item.name}
                        </span>
                        {item.description && (
                          <small className="block text-600">
                            {item.description}
                          </small>
                        )}
                      </div>
                      <span className="font-semibold text-dark-blue  ml-5 lg:ml-0 lg:mt-0">
                        {item.price}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="col-12 lg:col-2 flex align-items-center  justify-content-center"></div>
        <div className="col-12 lg:col-5">
          {categories.slice(halfIndex).map((category, index) => (
            <div className="mb-4 p-5" key={index}>
              <div className="relative">
                <div className="font-bold pb-2 text-3xl mb-5 uppercase text-dark-blue">
                  {category.name}
                </div>
              </div>
              <ul className="list-none p-0 m-0">
                {category.products &&
                  category.products.map((item, idx) => (
                    <li key={idx} className="flex justify-content-between my-5">
                      <div>
                        <span className="font-semibold text-lg text-dark-blue">
                          {item.name}
                        </span>
                        {item.description && (
                          <small className="block text-600">
                            {item.description}
                          </small>
                        )}
                      </div>
                      <span className="font-semibold text-dark-blue">
                        {item.price}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cafe;
