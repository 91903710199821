import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Main from "./main";
import Footer from "./footer";
const Layout = () => {
  return (
    <Fragment>
      <div className="">
        <Header />
        <Main>
          <Outlet />
        </Main>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Layout;
