import React, { useState, useEffect, Fragment } from "react";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { Divider } from "primereact/divider";
import { Calendar } from "primereact/calendar";
import { Ripple } from "primereact/ripple";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import eventService from "../../services/eventService";
import constantService from "../../services/constantService";
import gameService from "../../services/gameService";
import blogService from "../../services/blogService";
import { formatDateTime } from "../../utils/dateFormatter";

const Home = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [scene, setScene] = useState(null);
  const [games, setGames] = useState([]);
  const [blogs, setBlogs] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(null);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tempDates, setTempDates] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      setLoading(true);
      const response = await eventService.getAll(null);

      if (response) {
        setEvents(response);
        setFilteredEvents(response);
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    };

    const fetchScene = async () => {
      const response = await constantService.getSceneData();

      if (response) {
        setScene(response);
      }
    };

    const fetchGameData = async () => {
      const response = await gameService.getAll();

      if (response) {
        const sortedGames = response
          .filter((game) => game.status === 1)
          .sort((a, b) => a.position - b.position);
        setGames(sortedGames);
      }
    };

    const fetchBlogData = async () => {
      const response = await blogService.getAll();
      if (response) {
        setBlogs(response);
      }
    };

    fetchBlogData();

    fetchGameData();
    fetchEventData();
    fetchScene();
  }, []);

  const fetchEventDataByFilter = async (updatedFilter) => {
    setLoading(true);
    const response = await eventService.getAll(updatedFilter);

    if (response) {
      setFilteredEvents(response);
    }
    setLoading(false);
  };

  const handleFilterChange = (updatedFilter) => {
    setFilter(updatedFilter);
    fetchEventDataByFilter(updatedFilter);
  };

  const handleDateChange = (e) => {
    const selectedDates = e.value;
    setTempDates(selectedDates);

    if (
      selectedDates &&
      selectedDates.length === 2 &&
      selectedDates[1] !== null
    ) {
      const updatedFilter = {
        ...filter,
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      };
      handleFilterChange(updatedFilter);
    }
  };

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const sortedEvents = events
    ? events
        .filter((event) => event.status === 1)
        .sort((a, b) => a.position - b.position)
    : [];

  const eventTemplate = (event) => {
    return event ? (
      <div className="border-1 surface-border border-round m-2 text-center py-3 px-2">
        <div className="mb-3">
          <img
            onClick={async () => {
              navigate(`/etkinlik-detay/${event.id}`);
            }}
            src={event.image}
            className="w-12 shadow-2 cursor-pointer"
            alt="Event"
          />
        </div>
        <div>
          <div className="flex flex-wrap gap-2 justify-content-center">
            <Button
              link
              onClick={() => window.open(event.link, "_blank")}
              icon="pi pi-link"
              label="Satın Al"
              className="background-dark-blue  p-button p-button-rounded text-white"
            />
            <Button
              icon="pi pi-turkish-lira font-semibold"
              iconPos="right"
              className="bg-white p-button-success p-button-rounded font-semibold"
              label={event.price}
            />
          </div>
        </div>
      </div>
    ) : null;
  };

  return (
    <Fragment>
      {loading ? (
        <div className="flex justify-content-center align-items-center min-h-screen">
          <ProgressSpinner />
        </div>
      ) : (
        <div className="background-traffic-white px-0 py-6 md:px-6 lg:px-6">
          <Carousel
            value={sortedEvents}
            numScroll={1}
            numVisible={4}
            responsiveOptions={responsiveOptions}
            itemTemplate={eventTemplate}
          />
        </div>
      )}
      <div className="background-traffic-white  pt-8 px-0 py-3 md:px-3 lg:px-6">
        <div className="font-bold text-dark-blue text-5xl mb-3 text-center uppercase">
          Etkinlik Takvimi
        </div>
        <div className="background-traffic-white border-round-lg  px-4 py-8 md:px-6 lg:px-8">
          <div className="flex-none lg:flex p-2 background-dark-blue border-round-lg justify-content-between align-items-center">
            <div className="flex-grow-1 mb-2 lg:mb-0">
              <Calendar
                dateFormat="dd/mm/yy"
                placeholder="Etkinlik Tarihi Aralığı"
                inputClassName="background-traffic-white custom-calendar-placeholder"
                panelClassName="background-dark-blue"
                value={tempDates}
                onChange={handleDateChange}
                className="w-full lg:w-4 background-dark-blue"
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                appendTo="self"
              />
            </div>
            <div className="flex justify-content-center md:justify-content-center gap-2 w-auto">
              <Button
                className={`${
                  !filter || !filter.country
                    ? "bg-white-alpha-90 text-black-alpha-90 border-none"
                    : "background-dark-blue text-white-alpha-90 border-none"
                }`}
                label="Tümü"
                onClick={() => {
                  const updatedFilter = { ...filter, country: null };
                  handleFilterChange(updatedFilter);
                }}
              />
              <Button
                className={`${
                  filter && filter.country === "Ankara"
                    ? "bg-white-alpha-90 text-black-alpha-90 border-none"
                    : "background-dark-blue hover:bg-white hover:text-black-alpha-90 text-white-alpha-90 border-none"
                }`}
                label="Ankara"
                onClick={() => {
                  const updatedFilter = { ...filter, country: "Ankara" };
                  handleFilterChange(updatedFilter);
                }}
              />
              <Button
                className={`${
                  filter && filter.country === "İstanbul"
                    ? "bg-white-alpha-90 text-black-alpha-90 border-none"
                    : "background-dark-blue hover:bg-white hover:text-black-alpha-90 text-white-alpha-90 border-none"
                }`}
                label="İstanbul"
                onClick={() => {
                  const updatedFilter = { ...filter, country: "İstanbul" };
                  handleFilterChange(updatedFilter);
                }}
              />
              <Button
                className={`${
                  filter && filter.country === "Eskişehir"
                    ? "bg-white-alpha-90 text-black-alpha-90 border-none"
                    : "background-dark-blue hover:bg-white hover:text-black-alpha-90 text-white-alpha-90 border-none"
                }`}
                label="Eskişehir"
                onClick={() => {
                  const updatedFilter = { ...filter, country: "Eskişehir" };
                  handleFilterChange(updatedFilter);
                }}
              />
            </div>
          </div>
          {loading ? (
            <div className="flex justify-content-center align-items-center min-h-screen">
              <ProgressSpinner />
            </div>
          ) : (
            <div className="col-12">
              {filteredEvents &&
                filteredEvents.map((event, index) => {
                  return (
                    <div
                      className="cursor-pointer"
                      onClick={async () => {
                        navigate(`/etkinlik-detay/${event.id}`);
                      }}
                      key={index}
                    >
                      <div
                        className="p-2 my-4 flex flex-column  background-dark-blue border-round-xl lg:flex-row justify-content-between align-items-center"
                        style={{ transition: "transform 0.3s ease-in-out" }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.05)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      >
                        <div className="flex flex-column  lg:flex-row justify-content-center align-items-center px-2">
                          <img
                            src={event.image}
                            alt="product"
                            className="w-10rem  mr-3 flex-shrink-0"
                          />
                          <div className="flex flex-column my-auto text-center md:text-left">
                            <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">
                              {event.name}
                            </span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  event && event.description
                                    ? event.description.length > 70
                                      ? `${event.description.substring(
                                          0,
                                          70
                                        )}...`
                                      : event.description
                                    : "",
                              }}
                              className="text-600  text-sm mb-3"
                            ></span>
                            <div
                              tabIndex="0"
                              className="p-ripple p-2 w-9rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-white text-white transition-duration-150"
                            >
                              {formatDateTime(event.date)}
                              <Ripple />
                            </div>

                            <div
                              tabIndex="0"
                              className="p-ripple p-2 mt-2 w-15rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-white text-white transition-duration-150"
                            >
                              {event.location}
                              <Ripple />
                            </div>
                          </div>
                        </div>
                        {event.status === 1 ? (
                          <div
                            className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center background-traffic-white"
                            style={{ borderRadius: "2.5rem" }}
                          >
                            <span
                              className="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2"
                              style={{
                                minWidth: "2rem",
                                minHeight: "2rem",
                              }}
                            >
                              <i className="pi pi-check"></i>
                            </span>
                            <span className="text-dark-blue">
                              Etkinlik Hala Satışta
                            </span>
                          </div>
                        ) : (
                          <div
                            className="bg-red-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center"
                            style={{ borderRadius: "2.5rem" }}
                          >
                            <span
                              className="bg-red-500 text-white flex align-items-center justify-content-center border-circle mr-2"
                              style={{
                                minWidth: "2rem",
                                minHeight: "2rem",
                              }}
                            >
                              <i className="pi pi-times"></i>
                            </span>
                            <span className="text-red-500">
                              Etkinlik Satışta Değil
                            </span>
                          </div>
                        )}
                      </div>
                      <Divider className="w-full block surface-border mb-6" />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>

      <div className="background-traffic-white px-4 py-8 md:px-6 lg:px-8">
        <div className="font-bold text-dark-blue text-5xl mb-8 text-center uppercase">
          Deneysel Sahne
        </div>
        <div className="grid">
          <div className="col-12 md:col-6 flex align-items-center">
            <div>
              <div className="text-dark-blue text-6xl font-bold mb-6">
                {scene && scene.title ? scene.title : null}
              </div>

              <div className="grid">
                <div className="col-12">
                  <p
                    className="text-dark-blue text-xl mb-3 line-height-4 pr-5"
                    dangerouslySetInnerHTML={{
                      __html:
                        scene && scene.description ? scene.description : "",
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <img
              src={scene && scene.image ? scene.image : null}
              alt="Image"
              className="w-full border-round-xl shadow-5"
            />
          </div>
        </div>
      </div>

      <div className="background-traffic-white min-h-full px-4 py-8 md:px-6 lg:px-8">
        <div className="font-bold text-dark-blue uppercase text-5xl mb-3 text-center">
          Oyunlar
        </div>

        <div className="grid grid-nogutter">
          {games.map((gameData, index) => (
            <div
              onClick={async () => {
                navigate(`/oyun-detay/${gameData.id}`);
              }}
              key={index}
              className="col-12 sm:col-6 lg:col-3 p-3 cursor-pointer"
            >
              <div className=" p-2 border-round-lg  h-full">
                <img src={gameData.image} alt="Game" className="mb-3 w-full" />
                <div className="flex justify-content-between align-items-center">
                  <div className="pr-3">
                    <div className="text-xl text-dark-blue font-medium mb-2">
                      {gameData.name}
                    </div>
                    <div className="text-dark-blue">
                      {gameData.description.length > 70
                        ? `${gameData.description.substring(0, 70)}...`
                        : gameData.description}
                    </div>
                  </div>
                </div>
                <div className="flex flex-nowrap mt-4 mb-2">
                  <Button
                    className="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2 background-dark-blue text-white"
                    icon="pi pi-link"
                    label="Satın Al"
                    link
                    onClick={() => window.open(gameData.link, "_blank")}
                  />
                  <Button
                    className="p-button-rounded text-white p-button-outlined p-button-plain background-dark-blue text-white"
                    icon="pi pi-turkish-lira"
                    iconPos="right"
                    label={gameData.price}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="background-traffic-white min-h-full px-4 py-8 md:px-6 lg:px-8">
        <div className="font-bold text-dark-blue uppercase text-5xl mb-3 text-center">
          Neler Yaptık
        </div>
        <div className="grid grid-nogutter">
          {blogs &&
            blogs.map((blog, index) => {
              return blog.status === 1 ? (
                <div
                  key={index}
                  onClick={async () => {
                    navigate(`/blog-detay/${blog.id}`);
                  }}
                  className="col-12 lg:col-3 px-3 cursor-pointer "
                >
                  <div className="p-3  border-round   h-full">
                    <h2 className="line-height-3 my-3 text-dark-blue font-medium">
                      {blog.title}
                    </h2>

                    <img
                      src={blog.image}
                      style={{ maxHeight: 400 }}
                      alt="Image"
                      className="block w-full mt-2 py-3"
                    />
                  </div>
                </div>
              ) : null;
            })}
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
