import React, { Fragment, useEffect, useState } from "react";
import gameService from "../../services/gameService";
import tMediaService from "../../services/tMediaService";
import { useParams } from "react-router-dom";
import { formatDateTime } from "../../utils/dateFormatter";
import { Galleria } from "primereact/galleria";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";

const GameDetail = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [allMedia, setAllMedia] = useState(null);
  const { id } = useParams();
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await gameService.get(id);
      if (response) {
        setSelectedGame(response);
      }
    };

    const fetchMedia = async () => {
      const response = await tMediaService.get(id);
      if (response) {
        setAllMedia(response);
      }
    };

    fetchData();
    fetchMedia();
  }, [id]);

  const itemTemplate = (item) => {
    return <img src={item.data} alt={item.alt} className="w-full" />;
  };

  const thumbnailTemplate = (item) => {
    return <img src={item.data} style={{ width: "90" }} alt={item.alt} />;
  };

  return (
    <Fragment>
      <div className="background-traffic-white min-h-full px-0 py-8 md:px-6 lg:px-8 text-dark-blue">
        {selectedGame && (
          <div className="game-detail-container p-p-4">
            <div className="grid">
              <div className="col-12 lg:col-8">
                <Tag
                  className="mr-2 surface-200 text-dark-blue"
                  value={formatDateTime(selectedGame.date)}
                />

                {selectedGame.link && (
                  <Tag
                    value="Satın Al"
                    icon="pi pi-link"
                    className="p-button-raised surface-200 text-dark-blue"
                    onClick={() => window.open(selectedGame.link, "_blank")}
                  />
                )}

                <h1 className="game-title text-4xl mt-3 mb-3 text-dark-blue">
                  {selectedGame.name}
                </h1>

                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedGame.description,
                  }}
                  className="line-height-3 mt-0 mb-5 text-dark-blue text-xl"
                ></p>

                <div className="game-location text-lg text-dark-blue mb-3">
                  <i className="pi pi-map-marker mr-2 text-white"></i>
                  {selectedGame.location}, {selectedGame.country}
                </div>

                {selectedGame.price > 0 && (
                  <div className="mt-4 text-lg text-dark-blue">
                    <i className="pi pi-tag mr-2"></i>
                    Fiyat: {selectedGame.price} ₺
                  </div>
                )}
              </div>
              <div className="col-12 lg:col-4">
                {allMedia && allMedia.length > 0 ? (
                  <Galleria
                    value={allMedia}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                  />
                ) : (
                  <Image
                    src={selectedGame.image}
                    alt={selectedGame.name}
                    imageClassName="border-round-lg"
                    className="border-round shadow-2"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default GameDetail;
