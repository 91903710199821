import axios from "axios";
import appConfig from "../config/appConfig";

const eventService = {
  getAll: async (filter) => {
    try {
      const response = await axios.post(
        `${appConfig.apiBaseUrl}/event/filter`,
        filter
      );
      return response.data;
    } catch (error) {
      console.error("Error getting event:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axios.get(`${appConfig.apiBaseUrl}/event/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting event with id ${id}:`, error);
    }
  },
};

export default eventService;
