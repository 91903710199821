import React, { Fragment, useEffect, useState } from "react";
import blogService from "../../services/blogService";
import { useParams } from "react-router-dom";
import { Image } from "primereact/image";

const BlogDetail = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await blogService.get(id);
      if (response) {
        setSelectedBlog(response);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Fragment>
      <div className="background-traffic-white min-h-full px-0 py-8 md:px-6 lg:px-8 text-dark-blue">
        {selectedBlog && (
          <div className="blog-detail-container p-p-4">
            <div className="grid">
              <div className="col-12 lg:col-8">
                <h1 className="blog-title text-4xl mt-3 mb-3 text-dark-blue">
                  {selectedBlog.title}
                </h1>

                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedBlog.description,
                  }}
                  className="line-height-3 mt-0 mb-5 text-dark-blue text-xl"
                ></p>
              </div>
              <div className="col-12 lg:col-4">
                <Image
                  src={selectedBlog.image}
                  alt={selectedBlog.title}
                  imageClassName="border-round-lg"
                  className="border-round shadow-2"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default BlogDetail;
