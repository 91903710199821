import React from "react";

const Contact = () => {
  return (
    <div className="background-dark-blue min-h-full">
      <div className="grid grid-nogutter">
        <div className="col-12 md:col-6 bg-no-repeat bg-cover p-4 lg:p-8">
          <div className="text-white text-6xl font-medium mb-6">
            Bize Ulaşın
          </div>
          <div className="text-white text-lg line-height-4 mb-6">
            Eskişehir Kumlubel adlı sokağın köşesinde Önceleri kafası karışık,
            azıcık aykırı bir kafeydi ama büyüdü ve tiyatro oldu. <br></br>
            <br></br>
            Eskibağlar, Dario Fo, Kumlubel Sk. No:8/D, 26170 Tepebaşı/Eskişehir,
            Eskisehir, Turkey 26170
          </div>

          <ul className="list-none p-0 m-0 mt-6 text-white text-lg">
            <li className="flex align-items-center mb-3">
              <i className="pi pi-phone mr-2"></i>
              <a className="text-white" href="tel:0537 049 99 72">
                0537 049 99 72
              </a>
            </li>

            <li className="flex align-items-center">
              <i className="pi pi-inbox mr-2"></i>
              <a className="text-white" href="mail:berkan0kaplan@gmail.com">
                info@dariofo.com.tr
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 md:col-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3066.04814234204!2d30.50980477657385!3d39.78347497154846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cc15cd87d3dd27%3A0xcd07968eb730da8f!2sDario%20Fo%20Kafe-%20Deneysel%20Sahne-%20Mutfak!5e0!3m2!1str!2str!4v1716059233973!5m2!1str!2str"
            className="border-round-xl mt-5 w-10 m-3"
            height="450"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
