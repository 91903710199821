import React, { Fragment, useEffect, useState } from "react";
import eventService from "../../services/eventService";
import { useParams } from "react-router-dom";
import { formatDateTime } from "../../utils/dateFormatter";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

const EventDetail = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await eventService.get(id);
      if (response) {
        setSelectedEvent(response);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Fragment>
      <div className="background-traffic-white min-h-full px-0 py-8 md:px-6 lg:px-8 text-dark-blue">
        {selectedEvent && (
          <div className="event-detail-container p-p-4">
            <div className="grid">
              <div className="col-12 lg:col-8">
                <Tag
                  className="mr-2 background-dark-blue text-white"
                  value={formatDateTime(selectedEvent.date)}
                />

                {selectedEvent.link && (
                  <Tag
                    value="Satın Al"
                    icon="pi pi-link"
                    className="p-button-raised background-dark-blue text-white"
                    onClick={() => window.open(selectedEvent.link, "_blank")}
                  />
                )}

                <h1 className="event-title text-4xl mt-3 mb-3 text-dark-blue">
                  {selectedEvent.name}
                </h1>

                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedEvent.description,
                  }}
                  className="line-height-3 mt-0 mb-5  text-dark-blue text-xl"
                ></p>

                <div className="event-location text-lg text-dark-blue mb-3">
                  <i className="pi pi-map-marker mr-2 text-dark-blue"></i>
                  {selectedEvent.location}, {selectedEvent.country}
                </div>

                {selectedEvent.price > 0 && (
                  <div className="mt-4 text-lg text-dark-blue">
                    <i className="pi pi-tag mr-2"></i>
                    Fiyat: {selectedEvent.price} ₺
                  </div>
                )}
              </div>
              <div className="col-12 lg:col-4">
                <Image
                  src={selectedEvent.image}
                  alt={selectedEvent.name}
                  imageClassName="border-round-lg"
                  className="border-round shadow-2"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default EventDetail;
