import axios from "axios";
import appConfig from "../config/appConfig";

const categoryService = {
  getAll: async () => {
    try {
      const response = await axios.get(`${appConfig.apiBaseUrl}/category`);
      return response.data;
    } catch (error) {
      console.error("Error getting category:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axios.get(
        `${appConfig.apiBaseUrl}/category/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error getting category with id ${id}:`, error);
    }
  },
};

export default categoryService;
