import React, { useState, useEffect, Fragment } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import gameService from "../../services/gameService";

const Games = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGameData = async () => {
      const response = await gameService.getAll();

      if (response) {
        const sortedGames = response
          .filter((game) => game.status === 1)
          .sort((a, b) => a.position - b.position);
        setGames(sortedGames);
      }
      setLoading(false);
    };

    fetchGameData();
  }, []);

  return (
    <Fragment>
      <div className="background-traffic-white min-h-full px-4 py-8 md:px-6 lg:px-8">
        <div className="font-bold text-dark-blue uppercase text-5xl mb-3 text-center">
          Oyunlar
        </div>

        {loading ? (
          <div className="flex justify-content-center align-items-center min-h-full">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="grid grid-nogutter">
            {games.map((gameData, index) => (
              <div
                onClick={async () => {
                  navigate(`/oyun-detay/${gameData.id}`);
                }}
                key={index}
                className="col-12 sm:col-6 lg:col-3 p-3 cursor-pointer"
              >
                <div className="p-2 border-round-lg h-full">
                  <img
                    src={gameData.image}
                    alt="Game"
                    className="mb-3 w-full"
                  />
                  <div className="flex justify-content-between align-items-center">
                    <div className="pr-3">
                      <div className="text-xl text-dark-blue font-medium mb-2">
                        {gameData.name}
                      </div>
                      <div className="text-dark-blue">
                        {gameData.description.length > 70
                          ? `${gameData.description.substring(0, 70)}...`
                          : gameData.description}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-nowrap mt-4 mb-2">
                    <Button
                      className="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2 background-dark-blue text-white"
                      icon="pi pi-link"
                      label="Satın Al"
                      link
                      onClick={() => window.open(gameData.link, "_blank")}
                    />
                    <Button
                      className="p-button-rounded text-white p-button-outlined p-button-plain background-dark-blue text-white"
                      icon="pi pi-turkish-lira"
                      iconPos="right"
                      label={gameData.price}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Games;
