import React, { Fragment, useEffect, useState } from "react";
import blogService from "../../services/blogService";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

const Blogs = () => {
  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await blogService.getAll();
      if (response) {
        setBlogs(response);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <Fragment>
      <div className="background-traffic-white min-h-full px-4 py-8 md:px-6 lg:px-8">
        {loading ? (
          <div className="flex justify-content-center align-items-center min-h-full">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="grid grid-nogutter">
            {blogs &&
              blogs.map((blog, index) => {
                return blog.status === 1 ? (
                  <div
                    key={index}
                    onClick={async () => {
                      navigate(`/blog-detay/${blog.id}`);
                    }}
                    className="col-12 lg:col-3 px-3 cursor-pointer "
                  >
                    <div className="p-3 border-round h-full">
                      <h2 className="line-height-3 my-3 text-dark-blue font-medium">
                        {blog.title}
                      </h2>

                      <img
                        src={blog.image}
                        style={{ maxHeight: 400 }}
                        alt="Image"
                        className="block w-full mt-2 py-3"
                      />
                    </div>
                  </div>
                ) : null;
              })}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Blogs;
