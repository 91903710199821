import React, { Fragment, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const btnRef4 = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`header py-3 px-8 mx-0 shadow-6  flex align-items-center justify-content-between relative lg:static border-gray-800 ${
          isScrolled ? "background-dark-blue" : "background-traffic-white"
        }`}
      >
        <img
          onClick={() => navigate("/")}
          src={isScrolled ? "/images/logo.png" : "/images/logo-lacivert.png"}
          alt="bastion-300"
          height={65}
          className="mr-0 lg:mr-6 cursor-pointer"
        />
        <StyleClass
          nodeRef={btnRef4}
          selector="@next"
          enterClassName="hidden"
          leaveToClassName="hidden"
          hideOnOutsideClick
        >
          <a
            ref={btnRef4}
            className={`p-ripple cursor-pointer block lg:hidden  ${
              isScrolled ? "text-white" : "text-dark-blue"
            }`}
          >
            <i className="pi pi-bars text-4xl"></i>
            <Ripple />
          </a>
        </StyleClass>
        <div
          className={`align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full ${
            isScrolled ? "background-dark-blue" : "background-traffic-white"
          } left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none ${
            isScrolled ? "border-gray-200" : "border-gray-800"
          }`}
        >
          <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row uppercase">
            <li onClick={() => navigate("/")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Anasayfa</span>
              </div>
            </li>
            <li onClick={() => navigate("/hakkimizda")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Hakkımızda</span>
              </div>
            </li>

            <li onClick={() => navigate("/etkinlikler")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Etkinlikler</span>
              </div>
            </li>
            <li onClick={() => navigate("/oyunlar")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Oyunlar</span>
              </div>
            </li>
            <li onClick={() => navigate("/ekip")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Ekibimiz</span>
              </div>
            </li>
            <li onClick={() => navigate("/galeri")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Galeri</span>
              </div>
            </li>
            <li onClick={() => navigate("/yazilar")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Neler Yaptık</span>
              </div>
            </li>
            <li onClick={() => navigate("/menu")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Kafe</span>
              </div>
            </li>
            <li onClick={() => navigate("/iletisim")}>
              <div
                className={`flex px-6 p-3 lg:px-3 lg:py-2 align-items-center ${
                  isScrolled ? "text-white" : "text-dark-blue"
                } hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full`}
              >
                <span>Bize Ulaşın</span>
              </div>
            </li>
          </ul>
          <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row uppercase">
            <div className=" font-semibold">
              <img
                src={
                  isScrolled
                    ? "/images/about_us_text.png"
                    : "/images/about_us_text_dark.png"
                }
                className="h-3rem mt-2 lg:block hidden border-round"
              />
            </div>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
