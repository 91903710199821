import axios from "axios";
import appConfig from "../config/appConfig";

const blogService = {
  getAll: async () => {
    try {
      const response = await axios.get(`${appConfig.apiBaseUrl}/blog`);
      return response.data;
    } catch (error) {
      console.error("Error getting blog:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axios.get(`${appConfig.apiBaseUrl}/blog/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting blog with id ${id}:`, error);
    }
  },
};

export default blogService;
