import React, { useState, useEffect, Fragment } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner"; // Import ProgressSpinner
import eventService from "../../services/eventService";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for spinner
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventData = async () => {
      const response = await eventService.getAll();

      if (response) {
        const sortedEvents = response
          .filter((event) => event.status === 1)
          .sort((a, b) => a.position - b.position);
        setEvents(sortedEvents);
      }
      setLoading(false); // Set loading to false once events are fetched
    };

    fetchEventData();
  }, []);

  return (
    <Fragment>
      <div className="background-traffic-white min-h-full px-4 py-8 md:px-6 lg:px-8">
        <div className="font-bold text-dark-blue uppercase text-5xl mb-3 text-center">
          Etkinlikler
        </div>

        {loading ? ( // Show spinner when loading is true
          <div className="flex justify-content-center align-items-center min-h-full">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="grid grid-nogutter">
            {events.map((eventData, index) => (
              <div
                onClick={async () => {
                  navigate(`/etkinlik-detay/${eventData.id}`);
                }}
                key={index}
                className="col-12 sm:col-6 lg:col-3 p-3 cursor-pointer"
              >
                <div className="p-2 border-round-lg  h-full">
                  <img
                    src={eventData.image}
                    alt="Event"
                    className="mb-1 w-full border-round-md"
                  />

                  <div className="flex flex-nowrap  mb-2">
                    <Button
                      className="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2 background-dark-blue text-white"
                      icon="pi pi-link"
                      label="Satın Al"
                      link
                      onClick={() => window.open(eventData.link, "_blank")}
                    />
                    <Button
                      className="p-button-rounded text-white p-button-outlined p-button-plain background-dark-blue text-white"
                      icon="pi pi-turkish-lira"
                      iconPos="right"
                      label={eventData.price}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Events;
