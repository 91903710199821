import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import memberService from "../../services/memberService";
import { ProgressSpinner } from "primereact/progressspinner";

const Team = () => {
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleMember, setVisibleMember] = useState(null);

  useEffect(() => {
    const fetchMemberData = async () => {
      const response = await memberService.getAll();

      if (response) {
        setMembers(response);
      }
      setLoading(false); // Data is loaded, stop loading
    };

    fetchMemberData();
  }, []);

  return (
    <div className="background-traffic-white min-h-full px-0 py-6 md:px-6 lg:px-6">
      <div className="font-bold text-dark-blue uppercase text-5xl mb-3 text-center">
        Ekibimiz
        <div className="background-traffic-white px-4 py-8 md:px-6 lg:px-8">
          {loading ? ( // Display spinner while loading
            <div className="flex justify-content-center align-items-center min-h-screen">
              <ProgressSpinner /> {/* PrimeReact Spinner */}
            </div>
          ) : (
            <div className="grid">
              {members && members.length > 0 ? (
                members.map((member, index) =>
                  member.status === 1 ? (
                    <div
                      key={index}
                      className="col-12 md:col-6 lg:col-3 p-3 cursor-pointer"
                    >
                      <div
                        className="relative overflow-hidden"
                        onMouseEnter={() => setVisibleMember(index)}
                        onMouseLeave={() => setVisibleMember(null)}
                      >
                        <img
                          src={member.image}
                          alt={`${member.name} ${member.surname}`}
                          className="w-full block border-round-lg"
                        />
                        <div
                          className="absolute top-0 left-0 h-full w-full border-round fadein animation-duration-300 select-none"
                          style={{
                            backgroundColor:
                              visibleMember !== index
                                ? "rgba(0,0,0,0.7)"
                                : "transparent",
                          }}
                        >
                          <div className="flex flex-column p-5 h-full">
                            {visibleMember !== index ? (
                              <div>
                                <span className="block font-medium text-white text-xl mb-3">
                                  {member.name} {member.surname}
                                </span>
                                <span className="font-medium text-400">
                                  {member.role}
                                </span>
                              </div>
                            ) : null}

                            <div className="mt-auto">
                              <a
                                href={member.twitterAddress}
                                target="_blank"
                                className="cursor-pointer text-white"
                                rel="noopener noreferrer"
                              >
                                <i className="pi pi-twitter text-600 text-xl mr-3"></i>
                              </a>
                              <a
                                href={member.instagramAddress}
                                target="_blank"
                                className="cursor-pointer text-white"
                                rel="noopener noreferrer"
                              >
                                <i className="pi pi-instagram text-600 text-xl mr-3"></i>
                              </a>
                              <a
                                href={member.facebookAddress}
                                target="_blank"
                                className="cursor-pointer text-white"
                                rel="noopener noreferrer"
                              >
                                <i className="pi pi-facebook text-600 text-xl mr-3"></i>
                              </a>
                              <a
                                onClick={async () => {
                                  navigate(`/ekip-uye-detay/${member.id}`);
                                }}
                                className="cursor-pointer text-white"
                              >
                                <i className="pi pi-link text-600 text-xl"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                )
              ) : (
                <p>No members found</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;
