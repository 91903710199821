import React, { Fragment } from "react";
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/layout";

import Home from "./pages/home/home";
import Gallery from "./pages/gallery/gallery";
import About from "./pages/about/about";
import Events from "./pages/events/events";
import Games from "./pages/games/games";
import Contact from "./pages/contact/contact";
import Cafe from "./pages/cafe/cafe";
import Blogs from "./pages/blogs/blog";

import BlogDetail from "./pages/details/blogDetail";
import EventDetail from "./pages/details/eventDetail";
import GameDetail from "./pages/details/gameDetail";
import MemberDetail from "./pages/details/memberDetail";
import Team from "./pages/team/team";

const App = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/hakkimizda" element={<About />} />
            <Route path="/menu" element={<Cafe />} />
            <Route path="/etkinlikler" element={<Events />} />
            <Route path="/oyunlar" element={<Games />} />
            <Route path="/yazilar" element={<Blogs />} />
            <Route path="/galeri" element={<Gallery />} />
            <Route path="/iletisim" element={<Contact />} />
            <Route path="/blog-detay/:id" element={<BlogDetail />} />
            <Route path="/etkinlik-detay/:id" element={<EventDetail />} />
            <Route path="/oyun-detay/:id" element={<GameDetail />} />
            <Route path="/ekip" element={<Team />} />
            <Route path="/ekip-uye-detay/:id" element={<MemberDetail />} />
          </Route>
          <Route index path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
