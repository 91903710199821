const apiBaseUrl = "https://api.dariofo.com.tr/api";
const rootUrl = "http://localhost:3000";

export const appConfig = {
  apiBaseUrl,
  rootUrl,
  apiRoute: (route) => {
    return `${apiBaseUrl}${route}`;
  },
};

export default appConfig;
