import React, { Fragment, useEffect, useState } from "react";
import constantService from "../../services/constantService";

const About = () => {
  const [aboutUs, setAboutUs] = useState(null);

  useEffect(() => {
    const fetchAbout = async () => {
      const response = await constantService.getAboutUsData();

      if (response) {
        setAboutUs(response);
      }
    };

    fetchAbout();
  }, []);

  return (
    <Fragment>
      <div className="background-traffic-white flex flex-column lg:flex-row px-4 py-8 md:px-6 lg:px-8 align-items-center">
        <img
          src={aboutUs && aboutUs.image ? aboutUs.image : null}
          className="h-30rem border-round"
        />
        <div className="bg-no-repeat bg-cover lg:border-noround-left border-round-xl px-4 py-7 sm:px-7 md:px-6">
          <div className="fadein animation-duration-3000 animation-iteration-infinite text-indigo-50 text-7xl font-semibold mb-3">
            <img
              src="images/about_us_text_dark.png"
              className="h-10rem border-round"
            />
          </div>
          <p
            className="text-xl text-dark-blue mt-0 mb-5 line-height-4"
            dangerouslySetInnerHTML={{
              __html: aboutUs && aboutUs.description ? aboutUs.description : "",
            }}
          ></p>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
